import React from "react";

const Termcondition = () => {
  //const currentUser = AuthService.getCurrentUser();

  return (
    <div className="container">
      <header className="jumbotron">
        <h3 style={{ textAlign: "center", margin: "10px", fontSize: "20px", color: "", fontFamily: 'Kanit' }}>
          <strong>
            ข้อตกลงการใช้งาน
          </strong>
        </h3>
      </header>
      <p style={{ textAlign: "lelf", margin: "10px", fontSize: "18px", color: "", fontFamily: 'Kanit' }}>
        <strong>ร่างนโยบายคุกกี้:</strong>
      </p>
      <p style={{ textAlign: "", margin: "10px", fontSize: "16px", color: "", fontFamily: 'Kanit' }}>
        สำนักงานพัฒนาเทคโนโลยีอวกาศและภูมิสารสนเทศ (องค์การมหาชน) มีการใช้คุกกี้ เพื่อบันทึกกิจกรรมการเข้าเยี่ยมชม หรือใช้บริการเว็บไซต์ของเจ้าของข้อมูลส่วนบุคคล เพื่อให้เว็บไซต์สามารถให้บริการเจ้าของข้อมูลส่วนบุคคลได้มีประสิทธิภาพมากยิ่งขึ้นโดยข้อมูลคุกกี้จะถูกนำไปใช้เพื่อวัตถุประสงค์สำหรับปรับปรุงเว็บไซต์ของสำนักงานพัฒนาเทคโนโลยีอวกาศและภูมิสารสนเทศ (องค์การมหาชน) ให้ตรงกับการทำงานมากยิ่งขึ้น และเพื่ออำนวยความสะดวกให้แก่เจ้าของข้อมูลส่วนบุคคลในการใช้งานเว็บไซต์ ของสำนักงานพัฒนาเทคโนโลยีอวกาศและภูมิสารสนเทศ (องค์การมหาชน) ในอนาคต <br/>

        คุกกี้ที่สำนักงานพัฒนาเทคโนโลยีอวกาศและภูมิสารสนเทศ (องค์การมหาชน)  &nbsp;
        <br />
        1. คุกกี้ที่มีความจำเป็น (Strictly Necessary Cookies) คุกกี้ประเภทนี้มีความจำเป็นต่อการให้บริการเว็บไซต์ของสำนักงานพัฒนาเทคโนโลยีอวกาศและภูมิสารสนเทศ (องค์การมหาชน) เพื่อให้เจ้าของข้อมูลส่วนบุคคลสามารถเข้าใช้งานในส่วนต่าง ๆ ของเว็บไซต์ได้ รวมถึงช่วยจดจำข้อมูลที่ท่านเคยให้ไว้ ผ่านเว็บไซต์
        <br />
        2. คุกกี้เพื่อการวิเคราะห์และประเมินผลการใช้งาน (Performance Cookies) คุกกี้ประเภทนี้ช่วยให้ สำนักงานพัฒนาเทคโนโลยีอวกาศและภูมิสารสนเทศ (องค์การมหาชน) ทราบถึงการปฏิสัมพันธ์ ของผู้ใช้งานในการใช้บริการเว็บไซต์ของสำนักงานพัฒนาเทคโนโลยีอวกาศและภูมิสารสนเทศ (องค์การมหาชน) รวมถึงหน้าเพจหรือพื้นที่ใดของเว็บไซต์ที่ได้รับความนิยม ตลอดจนการวิเคราะห์ข้อมูล ด้านอื่น ๆ สำนักงานพัฒนาเทคโนโลยีอวกาศและภูมิสารสนเทศ (องค์การมหาชน) ยังใช้ข้อมูลนี้เพื่อการปรับปรุงการทำงานของเว็บไซต์ และเพื่อเข้าใจพฤติกรรมของผู้ใช้งานมากขึ้น โดยข้อมูลที่คุกกี้เก็บรวบรวม จะเป็นข้อมูลที่ไม่สามารถระบุตัวตนได้ และนำมาใช้วิเคราะห์ทางสถิติเท่านั้น
        <br />
        3. คุกกี้เพื่อการใช้งานเว็บไซต์ (Functional Cookies) คุกกี้ประเภทนี้จะช่วยให้เว็บไซต์ ของสำนักงานพัฒนาเทคโนโลยีอวกาศและภูมิสารสนเทศ (องค์การมหาชน) จดจำตัวเลือกต่าง ๆ ที่ได้ตั้งค่าไว้และช่วยให้เว็บไซต์ส่งมอบคุณสมบัติและเนื้อหาเพิ่มเติมให้ตรงกับการใช้งานของเจ้าของข้อมูลส่วนบุคคลได้ เช่น ช่วยจดจำชื่อบัญชีผู้ใช้งาน หรือจดจำการเปลี่ยนแปลงการตั้งค่าต่าง ๆ ของหน้าเว็บไซต์ซึ่งเจ้าของข้อมูลส่วนบุคคลสามารถปรับแต่งได้
        <br />
        ทั้งนี้เจ้าของข้อมูลส่วนบุคคลสามารถเลือกที่จะปิดการทำงานหรือลบข้อมูลคุกกี้บนอุปกรณ์ ของเจ้าของข้อมูลส่วนบุคคลได้เอง แต่อาจส่งผลกระทบกับการทำงานบางส่วนของเว็บไซต์ของของสำนักงานพัฒนาเทคโนโลยีอวกาศและภูมิสารสนเทศ (องค์การมหาชน)
      </p>
      {/* <p>
        <strong>Id:</strong> {currentUser.id}
      </p> */}
      {/* <p>
        <strong>tel:</strong> {currentUser.tel}
      </p>
      <p>
        <strong>Email:</strong> {currentUser.email}
      </p> */}
      {/* <strong>Authorities:</strong>
      <ul>
        {currentUser.roles &&
          currentUser.roles.map((role, index) => <li key={index}>{role}</li>)}
      </ul> */}
    </div>
  );
};

export default Termcondition;
