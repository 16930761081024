import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
// import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { CardActionArea, CardActions } from '@mui/material';
import CardMedia from '@mui/material/CardMedia';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
// import Typography from '@mui/material/Typography';
// import TextField from '@mui/material/TextField';
//import { Button,InputGroup, Badge, Container, Card, CardGroup, ListGroup, Col, Row, Nav, Navbar, NavDropdown, Form, Image } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import { FaEye, FaEyeSlash } from 'react-icons/fa';


import AuthService from "../services/auth.service";
import ReactGA from "react-ga4";

const required = (value) => {
  if (!value) {
    return (
      <div className="invalid-feedback d-block">
        กรุณากรอกข้อมูลให้ครบถ้วน!
      </div>
    );
  }
};

const vtel = (value) => {
  if (value.length < 10 || value.length > 10) {
    return (
      <div className="invalid-feedback d-block">
        {/* The tel must be 10 characters. */}
        หมายเลขเบอร์โทร (Phone Number) ควรมี 10 ตัวเลข
      </div>
    );
  }
};


const ForgetPassword = () => {
  const form = useRef();
  const checkBtn = useRef();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [tel, setTel] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [message2, setMessage2] = useState("");
  const [successful, setSuccessful] = useState(false);



  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };


  const navigate = useNavigate();

  const onChangeUsername = (e) => {
    const username = e.target.value;
    setUsername(username);
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };

  const onChangeTel = (e) => {
    const tel = e.target.value;
    setTel(tel);
  };

  const handleForgetpassword = (e) => {
    e.preventDefault();

    setMessage("");
    setSuccessful(false);
    //setLoading(true);

    form.current.validateAll();

    if (checkBtn.current.context._errors.length === 0) {
      AuthService.forgotpassword(username, password, tel).then(
        (response) => {
          // navigate("/profile");
          // window.location.reload();
          setMessage(response.data.message);
          setMessage2(response.data.chk);
          console.log(response.data.message)



        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.chk) ||
            error.message ||
            error.toString();

          setLoading(false);
          //setSuccessful(false);
          setMessage(resMessage);
        }
      );
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    const TRACKING_ID = "G-6VFTN9NWRE"
    ReactGA.initialize(TRACKING_ID)
    ReactGA.send({ hitType: "pageview", page: "/forgetPassword", title: "forgetPassword" });
  }, [])

  return (


    <div className="col-md-12">



      <div className="card card-container" border="primary" style={{ maxWidth: '25rem', paddingTop: '0.5rem' }}>

        {/* <img
          src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
          alt="profile-img"
          className="profile-img-card"
        /> */}
        {/* <InputGroup.Append>
          <InputGroup.Text>
            <i onClick={clickHandler} class={showPass ? 'fas fa-eye-slash' : 'fas fa-eye'}></i>
          </InputGroup.Text>
        </InputGroup.Append> */}

        <span style={{ textAlign: 'center', fontFamily: 'Kanit', fontSize: 28 }}>ลืมรหัสผ่าน</span>
        &nbsp;&nbsp;
        <Form onSubmit={handleForgetpassword} ref={form}>
          {!successful && (
            <div>
              <div className="form-group">
                <label htmlFor="username">ผู้ใช้งาน (Username)</label>
                <Input
                  type="text"
                  className="form-control"
                  name="username"
                  value={username}
                  onChange={onChangeUsername}
                  validations={[required]}
                />
              </div>
              <div className="form-group">
                <label htmlFor="tel">เบอร์โทร (Phone Number)</label>
                <Input
                  type="number"
                  className="form-control"
                  name="tel"
                  value={tel}
                  onChange={onChangeTel}
                  validations={[required, vtel]}
                />
              </div>
              <div className="form-group">
                <label htmlFor="password">รหัสผ่านใหม่ (Password)</label>
                <Input
                  type={showPassword ? "text" : "password"}
                  className="form-control"
                  name="password"
                  value={password}
                  onChange={onChangePassword}
                  validations={[required]}
                />
              </div>
              &nbsp;
              <Button style={{ marginTop: '-20px' }} className="" variant="light" size="sm" onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? <FaEyeSlash /> : <FaEye />} แสดงรหัสผ่าน
              </Button>

              <div className="form-group">
                <button className="btn btn-primary btn-block" disabled={loading}>
                  {loading && (

                    <span className="spinner-border spinner-border-sm"></span>
                  )}
                  <span>เปลี่ยนรหัสผ่าน</span>
                </button>
              </div>
            </div>
          )}


          {message && (

            <div className="form-group">
              <div
                className={
                  message == 0 ? "alert alert-danger" : "alert alert-success"
                }
                role="alert"
              >
                {message2}

              </div>
            </div>
          )}
          <CheckButton style={{ display: "none" }} ref={checkBtn} />
        </Form>

        <span style={{ textAlign: 'center', fontFamily: 'Kanit', fontSize: 16 }}>กลับไปยังหน้า
          <a style={{ color: '#ffc107' }} href="/login" className="outline-success">
            เข้าสู่ระบบ
          </a>
        </span>
      </div>
    </div>


  );
};

export default ForgetPassword;
