import React, { useState, useEffect, useRef } from "react";
import AuthService from "../services/auth.service";
import ReactGA from "react-ga4";

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';

import PropTypes from 'prop-types';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import {
  Button,
  // Collapse,
  // IconButton,
  // Table,
  // TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  // Paper,
  // CircularProgress,
  // Skeleton,
  // getIconButtonUtilityClass
} from "@mui/material";

import './Download.css';



function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}



const Profile = () => {
  const currentUser = AuthService.getCurrentUser();

  if (currentUser == null) { window.location = "/login" }
  // console.log(currentUser)
  const [value, setValue] = React.useState(0);
  const [DRIYearValue, setDRIYearValue] = React.useState(null);
  const [DRIWeekValue, setDRIWeekValue] = React.useState(null);

  const [CDAIPvValue, setCDAIPvValue] = React.useState(null);
  const [CDAIYearValue, setCDAIYearValue] = React.useState(null);
  const [CDAIWeekValue, setCDAIWeekValue] = React.useState(null);

  const [DSIPvValue, setDSIPvValue] = React.useState(null);
  const [DSIYearValue, setDSIYearValue] = React.useState(null);
  const [DSIWeekValue, setDSIWeekValue] = React.useState(null);


  const [DRIList, setDRIList] = React.useState([]);
  const [CDAIList, setCDAIList] = React.useState([]);
  const [DRIWeeksList, setDRIWeeksList] = React.useState([]);
  const [CDAIWeeksList, setCDAIWeeksList] = React.useState([]);

  const [GetWeek, setGetWeek] = React.useState([]);


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getData = async (type, province, year, week) => {
    week = String(week).padStart(2, '0');

    setGetWeek(week)
    //console.log(week)

    let url = ``;
    //if (type == 'cdai') { url = `https://drought-765rkyfg3q-as.a.run.app/get/tif?province=${province}&year=${year}&week=w${week}` }
    if (type == 'dri') { url = `https://drought-765rkyfg3q-as.a.run.app/get/dri/tif?&year=${year}&week=w${week}` }

    const response = await fetch(url);
    const result = await response.json();
    let data = [];
    for (let i = 0; i < result.data.file.length; i++) {
      data.push({ filename: result.data.file[i].split("/")[result.data.file[i].split("/").length - 1], path: result.data.file_name_[i] })
    }//end for
    //console.log(data)

    //if (type == 'cdai') { setCDAIList(data) }
   if (type == 'dri') { setDRIList(data) }



  };


  const getDataCDAI = async (type, province, year, week) => {
    week = String(week).padStart(2, '0');

    setGetWeek(week)
    //console.log(week)

    let url = ``;
    if (type == 'cdai') { url = `https://drought-765rkyfg3q-as.a.run.app/get/tif?province=${province}&year=${year}&week=w${week}` }

    const response = await fetch(url);
    const result = await response.json();
    console.log(result)
    let data = [];
    for (let i = 0; i < result.data.file.length; i++) {
      console.log(result.data.file)
      //data.push({ filename: result.data.file[i].name.split("/")})
      data.push({ filename: result.data.file[i].name.split("/")[result.data.file[i].name.split("/").length - 1], path: result.data.file_name_[i].mediaLink })
    }//end for
    console.log(data)

    if (type == 'cdai') { setCDAIList(data) }


  };


  const onDRIAutocompleteYearChange = (e, v) => {
    setDRIYearValue(v);
    setDRIWeekValue(null);
    if (DRIWeekValue) { getData('dri', null, v, DRIWeekValue) }
    let weeks = []
    for (let i = 1; i <= 52; i++) {
      let _year = v;
      let _week = i - 1;
      let days = (_week * 7) > 0 ? (_week * 7) - 1 : 0;
      let date = new Date(`${_year}-01-01`)
      let firstDateOfWeek = addDays(date, days+1)
      let lastDateOfWeek = addDays(firstDateOfWeek, 6)

      let firstday = firstDateOfWeek.toLocaleDateString('th-TH', { year: 'numeric', month: 'long', day: 'numeric' })
      let lastday = lastDateOfWeek.toLocaleDateString('th-TH', { year: 'numeric', month: 'long', day: 'numeric' })

      let r = `${firstday}` + ' ถึง ' + `${lastday}`;
      weeks.push(`${i} (${r})`);
    }
    setDRIWeeksList(weeks);
    console.log(weeks)
  
  };
  const onDRIAutocompleteWeekChange = (e, v) => {
    setDRIWeekValue(v);
    if (DRIYearValue) { getData('dri', null, DRIYearValue, v.split(" ")[0]) }
  };


  const onDSIAutocompleteProvinceChange = (e, v) => {
    setDSIPvValue(v);
    if (DSIYearValue && DSIWeekValue) { getData('dsi', v.label, DSIYearValue, DSIWeekValue) }
  };


  const onCDAIAutocompleteProvinceChange = (e, v) => {
    setCDAIPvValue(v);
    if (CDAIYearValue && CDAIWeekValue) { getDataCDAI('cdai', v.label, CDAIYearValue, CDAIWeekValue) }
  };
  const onCDAIAutocompleteYearChange = (e, v) => {
    setCDAIYearValue(v);
    setCDAIWeekValue(null);
    if (CDAIPvValue && CDAIWeekValue) { getDataCDAI('cdai', CDAIPvValue.label, v, CDAIWeekValue) }
    let weeks = []
    for (let i = 1; i <= 52; i++) {
      let _year = v;
      let _week = i - 1;
      let days = (_week * 7) > 0 ? (_week * 7) - 1 : 0;
      let date = new Date(`${_year}-01-01`)
      let firstDateOfWeek = addDays(date, days+1)
      let lastDateOfWeek = addDays(firstDateOfWeek, 6)

      var firstday = firstDateOfWeek.toLocaleDateString('th-TH', { year: 'numeric', month: 'long', day: 'numeric' })
      var lastday = lastDateOfWeek.toLocaleDateString('th-TH', { year: 'numeric', month: 'long', day: 'numeric' })

      let r = `${firstday}` + ' ถึง ' + `${lastday}`;
      weeks.push(`${i} (${r})`);
    }
    setCDAIWeeksList(weeks);
  };
  const onCDAIAutocompleteWeekChange = (e, v) => {
    setCDAIWeekValue(v);
    if (CDAIPvValue && CDAIYearValue) { getDataCDAI('cdai', CDAIPvValue.label, CDAIYearValue, v.split(" ")[0]) }
  };


  const downloadTiff = (path) => {
    window.location = path
  }

  const downloadZonalProvinceCSV = () => {
    // console.log('12323')
    window.location = `https://drought-765rkyfg3q-as.a.run.app/rest/getdri_provinceCSV`
  }
  const downloadZonalBasinCSV = () => {
    window.location = `https://drought-765rkyfg3q-as.a.run.app/rest/getdri_basinCSV`
  }
  const downloadZonalIrrigationCSV = () => {
    window.location = `https://drought-765rkyfg3q-as.a.run.app/rest/getdri_irrigationCSV`
  }



  useEffect(() => {

  
    const TRACKING_ID = "G-6VFTN9NWRE"
    ReactGA.initialize(TRACKING_ID)
    ReactGA.send({ hitType: "pageview", page: "/download", title: "download" });
    
    getData(years[1],'w05')

    setGetWeek(GetWeek)
    console.log(years[1])

    //console.log(CDAIList)
   

  }, [years]);





  return (
    <Container>
      <Row className="show-grid">
        <Col xs={12} md={0} lg={0}></Col>
        <Col xs={12} md={12} lg={12} >

          <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                <Tab style={{ fontFamily: 'Kanit', fontSize: 16 }} label="ดัชนีภัยแล้งรายสัปดาห์" {...a11yProps(0)} />
                <Tab style={{ fontFamily: 'Kanit', fontSize: 16 }} label="ดัชนีภัยแล้งต่อเนื่อง 4 สัปดาห์ (DSI)" {...a11yProps(1)} />
                <Tab style={{ fontFamily: 'Kanit', fontSize: 16, maxWidth: 500 }} label="ดัชนีความเสียหายของพืชเกษตรรายแปลง (CDAI)" {...a11yProps(2)} />
              </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
              <Row className="show-grid">
                <Col xs={12} md={6} lg={6} >
                  <Autocomplete
                    disablePortal id="dri-combo-year"
                    options={years}
                    value={DRIYearValue}
                    onChange={(e, v) => onDRIAutocompleteYearChange(e, v)}
                    sx={{ width: "100%", fontFamily: 'kanit', display: 'flex', marginTop: 2 }}
                    renderInput={(params) => <TextField  {...params} label="ปี" />}
                  />
                </Col>
                <Col xs={12} md={6} lg={6} >
                  <Autocomplete
                    disablePortal id="dri-combo-week"
                    options={DRIWeeksList}
                    value={DRIWeekValue}
                    onChange={(e, v) => onDRIAutocompleteWeekChange(e, v)}
                    sx={{ width: "100%", fontFamily: 'kanit', display: 'flex', marginTop: 2 }}
                    renderInput={(params) => <TextField  {...params} label="สัปดาห์" />}
                  />
                </Col>
              </Row>

              <Row style={{ marginTop: 21 }}>
                <Col xs={12} md={12} lg={12} >
                  <TableContainer style={{ height: 150, maxHeight: 400 }} sx={{
                    "&::-webkit-scrollbar": { width: 8, height: 8 }, "&::-webkit-scrollbar-track": { backgroundColor: "#FFFAD7" },
                    "&::-webkit-scrollbar-thumb": { backgroundColor: "#ffe670", borderRadius: 3 }, "&::-webkit-scrollbar-thumb:hover": { background: "#fac02e" }
                  }}>

                    {
                      DRIList.length == 0 && <div style={{ fontSize: 18, fontFamily: 'Kanit', textAlign: 'center', margin: 14 }}>ไม่พบข้อมูล</div>
                    }
                    {
                      DRIList.length > 0 &&
                      <div>
                        <TableRow style={{ borderBottom: "unset" }}>
                          <TableCell component="th" scope="row" align="center" style={{ borderWidth: 0 }} >
                            <div style={{ fontSize: 18, fontFamily: 'Kanit' }}> {'ลำดับ'} </div>
                          </TableCell>
                          <TableCell component="th" scope="row" align="center" style={{ borderWidth: 0, width: '100%' }} >
                            <div style={{ fontSize: 18, fontFamily: 'Kanit' }}> {'ไฟล์'} </div>
                          </TableCell>
                        </TableRow>
                      </div>
                    }
                    {
                      DRIList.map((rec, index) => (
                        <TableRow key={index} style={{ borderBottom: "unset" }}>
                          <TableCell component="th" scope="row" align="center" style={{ borderWidth: 0 }} >
                            <div style={{ fontSize: 20, fontFamily: 'Kanit' }}> {index + 1} </div>
                          </TableCell>
                          <TableCell component="th" scope="row" align="center" style={{ width: '100%' }} >
                            <Box style={{ fontSize: 20, fontFamily: 'Kanit', cursor: 'pointer' }} onClick={() => downloadTiff(rec.path)} > {rec.filename} </Box>
                          </TableCell>
                        </TableRow>
                      ))
                    }
                  </TableContainer>
                </Col>
              </Row>

            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <Row className="show-grid">
                <Col xs={12} md={4} lg={4} >
                  <Button variant="contained" style={{ fontFamily: 'Kanit', width: '100%' }} onClick={() => downloadZonalProvinceCSV()} >รายจังหวัด</Button>
                </Col>
                <Col xs={12} md={4} lg={4} >
                  <Button variant="contained" style={{ fontFamily: 'Kanit', width: '100%' }} onClick={() => downloadZonalBasinCSV()} >รายลุ่มน้ำ</Button>
                </Col>
                <Col xs={12} md={4} lg={4} >
                  <Button variant="contained" style={{ fontFamily: 'Kanit', width: '100%' }} onClick={() => downloadZonalIrrigationCSV()}>โครงการชลประทาน</Button>
                </Col>

              </Row>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
              <Row className="show-grid">
                <Col xs={12} md={4} lg={4} >
                  <Autocomplete
                    disablePortal id="cdai-combo-province"
                    options={provinces}
                    value={CDAIPvValue}
                    defaultValue={'W05'}
                    onChange={(e, v) => onCDAIAutocompleteProvinceChange(e, v)}
                    sx={{ width: "100%", fontFamily: 'kanit', display: 'flex', marginTop: 2 }}
                    renderInput={(params) => <TextField  {...params} label="จังหวัด" />}
                  />
                </Col>
                <Col xs={12} md={2} lg={2} >
                  <Autocomplete
                    disablePortal id="cdai-combo-year"
                    options={years}
                    //options={years}
                    value={CDAIYearValue}
                    //value={'2024'}
                    defaultValue={'2024'}
                    onChange={(e, v) => onCDAIAutocompleteYearChange(e, v)}
                    sx={{ width: "100%", fontFamily: 'kanit', display: 'flex', marginTop: 2 }}
                    renderInput={(params) => <TextField  {...params} label="ปี" />}
                  />
                </Col>
                <Col xs={12} md={6} lg={6} >
                  <Autocomplete
                    disablePortal id="cdai-combo-week"
                    options={CDAIWeeksList}
                    value={CDAIWeekValue}
                    onChange={(e, v) => onCDAIAutocompleteWeekChange(e, v)}
                    sx={{ width: "100%", fontFamily: 'kanit', display: 'flex', marginTop: 2 }}
                    renderInput={(params) => <TextField  {...params} label="สัปดาห์" />}
                  />
                </Col>
              </Row>

              <Row style={{ marginTop: 21 }}>
                <Col xs={12} md={12} lg={12} >
                  <TableContainer style={{ height: 600, maxHeight: 400 }} sx={{
                    "&::-webkit-scrollbar": { width: 8, height: 8 }, "&::-webkit-scrollbar-track": { backgroundColor: "#FFFAD7" },
                    "&::-webkit-scrollbar-thumb": { backgroundColor: "#ffe670", borderRadius: 3 }, "&::-webkit-scrollbar-thumb:hover": { background: "#fac02e" }
                  }}>

                    {
                      CDAIList == [] && <div style={{ fontSize: 18, fontFamily: 'Kanit', textAlign: 'center', margin: 14 }}>ไม่พบข้อมูล</div>
                    }
                    {
                      CDAIList.length > 0 &&
                      <div>
                        <TableRow style={{ borderBottom: "unset" }}>
                          <TableCell component="th" scope="row" align="center" style={{ borderWidth: 0 }} >
                            <div style={{ fontSize: 20, fontFamily: 'Kanit' }}> {'ลำดับ'} </div>
                          </TableCell>
                          <TableCell component="th" scope="row" align="center" style={{ borderWidth: 0, width: '100%' }} >
                            <div style={{ fontSize: 20, fontFamily: 'Kanit' }}> {'ไฟล์'} </div>
                          </TableCell>
                        </TableRow>
                      </div>
                    }
                    {
                      CDAIList.map((rec, index) => (
                        <TableRow key={index} style={{ borderBottom: "unset" }}>
                          <TableCell component="th" scope="row" align="center" style={{ borderWidth: 0 }} >
                            <div style={{ fontSize: 18, fontFamily: 'Kanit' }}> {index + 1} </div>
                          </TableCell>
                          <TableCell component="th" scope="row" align="center" style={{ width: '100%' }} >
                            <Box style={{ fontSize: 18, fontFamily: 'Kanit', cursor: 'pointer' }} onClick={() => downloadTiff(rec.path)} > {rec.filename} </Box>
                          </TableCell>
                        </TableRow>
                      ))
                    }
                  </TableContainer>
                </Col>
              </Row>
            </CustomTabPanel>

          </Box>

        </Col>
        <Col xs={12} md={0} lg={0}></Col>
      </Row>

      <Row style={{ margin: 14, background: '#fcfff5', borderColor: '#cadcc1', borderWidth: 2, borderStyle: 'solid', borderRadius: 7 }}>
        <Col xs={12} md={0} lg={0}></Col>
        <Col xs={12} md={12} lg={12} style={{ margin: 7 }}>
          <div style={{ color: "#527945", fontFamily: 'Kanit' }}>คำอธิบายข้อมูล</div>
          {/* <div style={{color : "#adc3a9", fontFamily: 'Kanit' }}>เป็นการประมวลผลเฉลี่ยข้อมูลเชิงพื้นที่ แยกตาม ข้อมูล Geometry ขอบเขตการปกครอง จังหวัด อำเภอ ตำบล จากข้อมูลพื้นที่เสี่ยงภัยแล้งราย 7 วัน ประมวลผลจากข้อมูลดาวเทียม</div> */}
          <div style={{ color: "#adc3a9", fontFamily: 'Kanit' }}>เป็นการประมวลผลเฉลี่ยข้อมูลเชิงพื้นที่จากข้อมูลพื้นที่เสี่ยงภัยแล้งราย 7 วัน ประมวลผลจากข้อมูลดาวเทียม</div>
        </Col>
        <Col xs={12} md={0} lg={0}></Col>
      </Row>
    </Container>


    // <div className="container">
    //   <header className="jumbotron">
    //     <h3>
    //       <strong>{currentUser.username}</strong> Profile
    //     </h3>
    //   </header>
    //   {/* <p>
    //     <strong>Token:</strong> {currentUser.accessToken}
    //   </p> */}
    //   {/* <p>
    //     <strong>Id:</strong> {currentUser.id}
    //   </p> */}
    //   <p>
    //     <strong>tel:</strong> {currentUser.tel}
    //   </p>
    //   <p>
    //     <strong>Email:</strong> {currentUser.email}
    //   </p>
    //   <strong>Authorities:</strong>
    //   <ul>
    //     {currentUser.roles &&
    //       currentUser.roles.map((role, index) => <li key={index}>{role}</li>)}
    //   </ul>
    // </div>
  );
};

export default Profile;


function addDays(date, days) {
  var result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}


let years = [];
for (let i = 2024; i <= new Date().getFullYear(); i++) {
  years.push(`${i}`);
}



const provinces = [
  { "label": "กำแพงเพชร", "pv_idn": 62 },
  { "label": "จันทบุรี", "pv_idn": 22 },
  { "label": "อุบลราชธานี", "pv_idn": 34 },
  { "label": "นครสวรรค์", "pv_idn": 60 },
  { "label": "กรุงเทพมหานคร", "pv_idn": 10 },
  { "label": "สมุทรสาคร", "pv_idn": 74 },
  { "label": "ชุมพร", "pv_idn": 86 },
  { "label": "นครปฐม", "pv_idn": 73 },
  { "label": "เชียงใหม่", "pv_idn": 50 },
  { "label": "ปัตตานี", "pv_idn": 94 },
  { "label": "ชัยภูมิ", "pv_idn": 36 },
  { "label": "พังงา", "pv_idn": 82 },
  { "label": "แพร่", "pv_idn": 54 },
  { "label": "ปทุมธานี", "pv_idn": 13 },
  { "label": "นนทบุรี", "pv_idn": 12 },
  { "label": "มุกดาหาร", "pv_idn": 49 },
  { "label": "พระนครศรีอยุธยา", "pv_idn": 14 },
  { "label": "ยะลา", "pv_idn": 95 },
  { "label": "กระบี่", "pv_idn": 81 },
  { "label": "ร้อยเอ็ด", "pv_idn": 45 },
  { "label": "ศรีสะเกษ", "pv_idn": 33 },
  { "label": "ตาก", "pv_idn": 63 },
  { "label": "บุรีรัมย์", "pv_idn": 31 },
  { "label": "อุดรธานี", "pv_idn": 41 },
  { "label": "หนองบัวลำภู", "pv_idn": 39 },
  { "label": "สกลนคร", "pv_idn": 47 },
  { "label": "ภูเก็ต", "pv_idn": 83 },
  { "label": "อุทัยธานี", "pv_idn": 61 },
  { "label": "หนองคาย", "pv_idn": 43 },
  { "label": "ลำปาง", "pv_idn": 52 },
  { "label": "ตรัง", "pv_idn": 92 },
  { "label": "นครศรีธรรมราช", "pv_idn": 80 },
  { "label": "กาฬสินธุ์", "pv_idn": 46 },
  { "label": "อำนาจเจริญ", "pv_idn": 37 },
  { "label": "อุตรดิตถ์", "pv_idn": 53 },
  { "label": "ฉะเชิงเทรา", "pv_idn": 24 },
  { "label": "มหาสารคาม", "pv_idn": 44 },
  { "label": "สมุทรปราการ", "pv_idn": 11 },
  { "label": "กาญจนบุรี", "pv_idn": 71 },
  { "label": "น่าน", "pv_idn": 55 },
  { "label": "นครพนม", "pv_idn": 48 },
  { "label": "เลย", "pv_idn": 42 },
  { "label": "ลพบุรี", "pv_idn": 16 },
  { "label": "สมุทรสงคราม", "pv_idn": 75 },
  { "label": "ระยอง", "pv_idn": 21 },
  { "label": "ประจวบคีรีขันธ์", "pv_idn": 77 },
  { "label": "สุราษฎร์ธานี", "pv_idn": 84 },
  { "label": "เพชรบุรี", "pv_idn": 76 },
  { "label": "ลำพูน", "pv_idn": 51 },
  { "label": "นครนายก", "pv_idn": 26 },
  { "label": "สุพรรณบุรี", "pv_idn": 72 },
  { "label": "ยโสธร", "pv_idn": 35 },
  { "label": "ขอนแก่น", "pv_idn": 40 },
  { "label": "สระแก้ว", "pv_idn": 27 },
  { "label": "ชัยนาท", "pv_idn": 18 },
  { "label": "นราธิวาส", "pv_idn": 96 },
  { "label": "สระบุรี", "pv_idn": 19 },
  { "label": "สตูล", "pv_idn": 91 },
  { "label": "พะเยา", "pv_idn": 56 },
  { "label": "ราชบุรี", "pv_idn": 70 },
  { "label": "พิษณุโลก", "pv_idn": 65 },
  { "label": "เชียงราย", "pv_idn": 57 },
  { "label": "สงขลา", "pv_idn": 90 },
  { "label": "ชลบุรี", "pv_idn": 20 },
  { "label": "แม่ฮ่องสอน", "pv_idn": 58 },
  { "label": "พัทลุง", "pv_idn": 93 },
  { "label": "อ่างทอง", "pv_idn": 15 },
  { "label": "ตราด", "pv_idn": 23 },
  { "label": "สิงห์บุรี", "pv_idn": 17 },
  { "label": "บึงกาฬ", "pv_idn": 38 },
  { "label": "นครราชสีมา", "pv_idn": 30 },
  { "label": "เพชรบูรณ์", "pv_idn": 67 },
  { "label": "สุโขทัย", "pv_idn": 64 },
  { "label": "ปราจีนบุรี", "pv_idn": 25 },
  { "label": "ระนอง", "pv_idn": 85 },
  { "label": "พิจิตร", "pv_idn": 66 },
  { "label": "สุรินทร์", "pv_idn": 32 }
];