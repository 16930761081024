import React, { useState, useRef,useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import ReactGA from "react-ga4";

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
// import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { CardActionArea, CardActions } from '@mui/material';
import CardMedia from '@mui/material/CardMedia';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
// import Typography from '@mui/material/Typography';
// import TextField from '@mui/material/TextField';
//import { Button,InputGroup, Badge, Container, Card, CardGroup, ListGroup, Col, Row, Nav, Navbar, NavDropdown, Form, Image } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import { FaEye, FaEyeSlash } from 'react-icons/fa';


import AuthService from "../services/auth.service";

const required = (value) => {
  if (!value) {
    return (
      <div className="invalid-feedback d-block">
        กรุณากรอกข้อมูลให้ครบถ้วน!
      </div>
    );
  }
};

const Login = () => {
  const form = useRef();
  const checkBtn = useRef();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");



  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };


  const navigate = useNavigate();

  const onChangeUsername = (e) => {
    const username = e.target.value;
    setUsername(username);
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };

  const handleLogin = (e) => {
    e.preventDefault();

    setMessage("");
    setLoading(true);

    form.current.validateAll();

    if (checkBtn.current.context._errors.length === 0) {
      AuthService.login(username, password).then(
        () => {
          navigate("/profile");
          window.location.reload();
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          setLoading(false);
          setMessage(resMessage);
        }
      );
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    const TRACKING_ID = "G-6VFTN9NWRE"
    ReactGA.initialize(TRACKING_ID)
    ReactGA.send({ hitType: "pageview", page: "/login", title: "login" });
 
  }, []);

  return (


    <div className="col-md-12">



      <div className="card card-container" border="primary" style={{ maxWidth: '25rem', paddingTop: '0.5rem' }}>

        {/* <img
          src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
          alt="profile-img"
          className="profile-img-card"
        /> */}
        {/* <InputGroup.Append>
          <InputGroup.Text>
            <i onClick={clickHandler} class={showPass ? 'fas fa-eye-slash' : 'fas fa-eye'}></i>
          </InputGroup.Text>
        </InputGroup.Append> */}

        <span style={{ textAlign: 'center', fontFamily: 'Kanit', fontSize: 28 }}>Cropsdrought Platform</span>
        <span style={{ textAlign: 'center', fontFamily: 'Kanit', fontSize: 28 }}>เข้าสู่ระบบ</span>
        &nbsp;&nbsp;
        <Form onSubmit={handleLogin} ref={form}>

          <div className="form-group">
            <label htmlFor="username">ผู้ใช้งาน (Username)</label>
            <Input
              type="text"
              className="form-control"
              name="username"
              value={username}
              onChange={onChangeUsername}
              validations={[required]}
            />
          </div>

          {/* <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
            <InputLabel htmlFor="outlined-adornment-password">รหัสผ่าน (Password)</InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              type={showPassword ? 'text' : 'password'}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
            />
          </FormControl> */}

          <div className="form-group">
            <label htmlFor="password">รหัสผ่าน (Password)</label>
            <Input
              type={showPassword ? "text" : "password"}
              className="form-control"
              name="password"
              value={password}
              onChange={onChangePassword}
              validations={[required]}
            />
          </div>
          &nbsp;
          <Button style={{ marginTop: '-20px' }} className="" variant="light" size="sm" onClick={() => setShowPassword(!showPassword)}>
            {showPassword ? <FaEyeSlash /> : <FaEye />} แสดงรหัสผ่าน
          </Button>

          <div className="form-group">
            <button className="btn btn-warning btn-block" disabled={loading}>
              {loading && (
                <span className="spinner-border spinner-border-sm"></span>
              )}
              <span>เข้าสู่ระบบ</span>
            </button>
          </div>

          {message && (
            <div className="form-group">
              <div className="alert alert-danger" role="alert">
                {message}
              </div>
            </div>
          )}
          <CheckButton style={{ display: "none" }} ref={checkBtn} />
        </Form>
        <span style={{ textAlign: 'right', fontFamily: 'Kanit', fontSize: 16 }}>
          <a style={{ color: '#212529' }} href="/forgetPassword" className="outline-success">
            ลืมรหัสผ่าน?
          </a>
        </span>


        <Alert style={{ fontSize: 16, fontFamily: 'Kanit' }} variant="outlined" icon={false} severity="warning">
          *เข้าสู่ระบบเพื่อติดตามสถานการณ์ภัยแล้งรายแปลง และดาวน์โหลดข้อมูล
        </Alert>
        &nbsp; &nbsp;

        <span style={{ textAlign: 'center', fontFamily: 'Kanit', fontSize: 16 }}>ถ้าคุณเป็นผู้ใช้งานใหม่ กรุณา
          <a style={{ color: '#198754' }} href="/register" className="outline-success">
            ลงทะเบียน
          </a>
        </span>
      </div>
    </div>


  );
};

export default Login;
