import React, { useState, useRef, useEffect } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { isEmail } from "validator";
import Alert from '@mui/material/Alert';
import Badge from 'react-bootstrap/Badge';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import "../components/Register.css";

//import Form from 'react-bootstrap/Form';


import { FaEye, FaEyeSlash } from 'react-icons/fa';

import AuthService from "../services/auth.service";
import ReactGA from "react-ga4";


const required = (value) => {
  if (!value) {
    return (
      <div className="invalid-feedback d-block">
        กรุณากรอกข้อมูลให้ครบถ้วน!
      </div>
    );
  }
};

const validEmail = (value) => {
  if (!isEmail(value)) {
    return (
      <div className="invalid-feedback d-block">
        {/* This is not a valid email. */}
        รูปแบบ อีเมล (Email) ไม่ถูกต้อง "ตัวอย่าง test@mail.com"
      </div>
    );
  }
};

const vusername = (value) => {
  if (value.length < 3 || value.length > 20) {
    return (
      <div className="invalid-feedback d-block">
        {/* The username must be between 3 and 20 characters. */}
        ชื่อผู้ใช้งาน (Username)ต้องมีความยาวอย่างน้อย3ถึง20ตัวอักษร
      </div>
    );
  }
};

const vpassword = (value) => {
  if (value.length < 6 || value.length > 40) {
    return (
      <div className="invalid-feedback d-block">
        {/* The password must be between 6 and 40 characters. */}
        รหัสผ่าน (Password) ต้องมีความยาวอย่างน้อย6ถึง40ตัวอักษร
      </div>
    );
  }
};

const vtel = (value) => {
  if (value.length < 10 || value.length > 10) {
    return (
      <div className="invalid-feedback d-block">
        {/* The tel must be 10 characters. */}
        หมายเลขเบอร์โทร (Phone Number) ควรมี 10 ตัวเลข
      </div>
    );
  }
};

const Register = (props) => {


  const form = useRef();
  const checkBtn = useRef();

  const [showPassword, setShowPassword] = useState(false);
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [tel, setTel] = useState("");
  const [successful, setSuccessful] = useState(false);
  const [message, setMessage] = useState("");




  const onChangeUsername = (e) => {
    const username = e.target.value;
    setUsername(username);
  };

  const onChangeEmail = (e) => {
    const email = e.target.value;
    setEmail(email);
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };

  const onChangeTel = (e) => {
    const tel = e.target.value;
    setTel(tel);
  };

  const handleRegister = (e) => {
    e.preventDefault();

    setMessage("");
    setSuccessful(false);

    form.current.validateAll();

    if (checkBtn.current.context._errors.length === 0) {
      AuthService.register(username, email, password, tel).then(
        (response) => {
          setMessage(response.data.message);
          setSuccessful(true);
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.chk) ||
            error.message ||
            error.toString();

          setMessage(resMessage);
          setSuccessful(false);
        }
      );
    }
  };

  useEffect(() => {
    const TRACKING_ID = "G-6VFTN9NWRE"
    ReactGA.initialize(TRACKING_ID)
    ReactGA.send({ hitType: "pageview", page: "/register", title: "register" });

  }, []);

  return (
    <div className="col-md-12">
      <div className="card card-container" border="primary" style={{ maxWidth: '25rem', paddingTop: '0.5rem' }}>
        {/*
        <img
          src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
          alt="profile-img"
          className="profile-img-card"
        /> */}

        <span style={{ textAlign: 'center', fontFamily: 'Kanit', fontSize: 28 }}>Cropsdrought Platform</span>
        <span style={{ textAlign: 'center', fontFamily: 'Kanit', fontSize: 28 }}>ลงทะเบียน</span>
        &nbsp;&nbsp;
        <Form onSubmit={handleRegister} ref={form}>
          {!successful && (
            <div>
              <div className="form-group">
                <label htmlFor="username">ผู้ใช้งาน (Username)</label>
                <Input
                  type="text"
                  className="form-control"
                  placeholder="ตัวอย่าง farmer"
                  name="username"
                  value={username}
                  onChange={onChangeUsername}
                  validations={[required, vusername]}
                />
              </div>

              <div className="form-group">
                <label htmlFor="password">รหัสผ่าน (Password)</label>
                <Input
                  style={{ flexWrap: 'nowrap', width: '-webkit-fill-available' }}
                  type={showPassword ? "text" : "password"}
                  className="form-control"
                  name="password"
                  value={password}
                  onChange={onChangePassword}
                  validations={[required, vpassword]}
                />

              </div>
              &nbsp;
              <Button style={{ marginTop: '-20px' }} className="" variant="light" size="sm" onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? <FaEyeSlash /> : <FaEye />} แสดงรหัสผ่าน
              </Button>





              <div className="form-group">
                <label htmlFor="email">อีเมล (Email)</label>
                <Input
                  type="text"
                  className="form-control"
                  name="email"
                  placeholder="ตัวอย่าง test@mail.com"
                  value={email}
                  onChange={onChangeEmail}
                  validations={[required, validEmail]}
                />
              </div>



              <div className="form-group">
                <label htmlFor="tel">เบอร์โทร (Phone Number)</label>
                <Input
                  type="number"
                  className="form-control"
                  name="tel"
                  value={tel}
                  onChange={onChangeTel}
                  validations={[required, vtel]}
                />
              </div>
              <br />

              <div className="form-group">
                <button className="btn btn-success btn-block">ลงทะเบียน</button>
              </div>
            </div>
          )}

          {message && (
            <div className="form-group">
              <div
                className={
                  successful ? "alert alert-success" : "alert alert-danger"
                }
                role="alert"
              >
                {message}
              </div>
            </div>
          )}
          <CheckButton style={{ display: "none" }} ref={checkBtn} />
        </Form>

        {/* <Alert style={{ fontSize: 16, fontFamily: 'Kanit' }} variant="outlined" icon={false} severity="success">
          *ลงทะเบียนเพื่อวาดแปลงเกษตรกรรม และดาวน์โหลดข้อมูล
        </Alert>
        &nbsp;&nbsp;&nbsp; */}
        <Alert style={{ fontSize: 16, fontFamily: 'Kanit' }} variant="outlined" icon={false} severity="success">
          *กรุณาจํา "ชื่อผู้ใช้งาน (Username)" และ "เบอร์โทร (Phone Number)" เพื่อใช้ในกรณีลืมรหัสผ่าน
        </Alert>
        &nbsp; &nbsp;
        <span style={{ textAlign: 'center', fontFamily: 'Kanit', fontSize: 16 }}>ถ้าคุณมีบัญชีในระบบอยู่แล้วกรุณา
          <a style={{ color: '#ffc107' }} href="/login" className="outline-success">
            เข้าสู่ระบบ
          </a>
        </span>
      </div>
    </div>
  );
};

export default Register;
