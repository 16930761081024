import React, { useState, useEffect } from "react";
import { Routes, Route, Link, Switch } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

import { Button, Container, Col, Row, Nav, Navbar, NavDropdown, Form, Offcanvas } from 'react-bootstrap';

import WebFont from 'webfontloader';

import ReactGA from "react-ga4";



import AuthService from "./services/auth.service";

import Login from "./components/Login";
import Register from "./components/Register";
import Home from "./components/Home";
import Stat from "./components/Stat";
import MapLayer from "./components/MapLayer";
import Download from "./components/Download";
import Profile from "./components/Profile";
import BoardUser from "./components/BoardUser";
import BoardUser2 from "./components/BoardUser2";
import BoardModerator from "./components/BoardModerator";
import BoardAdmin from "./components/BoardAdmin";
import ForgetPassword from "./components/forgetPassword";
import Termcondition from "./components/term-condition";
import Privacypolicy from "./components/privacy-policy";
import MapLayer_dev from "./components/MapLayer-dev";

import { NavLink } from "react-router-dom"



// import AuthVerify from "./common/AuthVerify";
import EventBus from "./common/EventBus";



const App = () => {
  const location = useLocation();
  const activeKey = location.pathname === '/' ? '/home' : location.pathname;

  const [showModeratorBoard, setShowModeratorBoard] = useState(false);
  const [showAdminBoard, setShowAdminBoard] = useState(false);
  const [currentUser, setCurrentUser] = useState(undefined);

  useEffect(() => {

    const TRACKING_ID = "G-6VFTN9NWRE"
    ReactGA.initialize(TRACKING_ID)
    ReactGA.send({ hitType: "pageview", page: "/", title: "App" });

 
    const user = AuthService.getCurrentUser();
    //console.log(user)
    
    if (user) {
      setCurrentUser(user);
      setShowModeratorBoard(user.roles.includes("ROLE_MODERATOR"));
      setShowAdminBoard(user.roles.includes("ROLE_ADMIN"));
    }

    EventBus.on("logout", () => {
      logOut();
    });

    return () => {
      EventBus.remove("logout");
    };
  }, []);

  const logOut = () => {
    AuthService.logout();
    setShowModeratorBoard(false);
    setShowAdminBoard(false);
    setCurrentUser(undefined);
  };

  return (
    <div>
      {/* <nav className="navbar navbar-expand navbar-dark bg-dark">
        <Link to={"/"} className="navbar-brand">
          bezKoder....
        </Link>
        <div className="navbar-nav mr-auto">
          <li className="nav-item">
            <Link to={"/home"} className="nav-link">
              Home
            </Link>
          </li>

          {showModeratorBoard && (
            <li className="nav-item">
              <Link to={"/mod"} className="nav-link">
                Moderator Board
              </Link>
            </li>
          )}

          {showAdminBoard && (
            <li className="nav-item">
              <Link to={"/admin"} className="nav-link">
                Admin Board
              </Link>
            </li>
          )}

          {currentUser && (
            <li className="nav-item">
              <Link to={"/user"} className="nav-link">
                User
              </Link>
            </li>
          )}
        </div>

        {currentUser ? (
          <div className="navbar-nav ml-auto">
            <li className="nav-item">
              <Link to={"/profile"} className="nav-link">
                {currentUser.username}
              </Link>
            </li>
            <li className="nav-item">
              <a href="/login" className="nav-link" onClick={logOut}>
                LogOut
              </a>
            </li>
          </div>
        ) : (
          <div className="navbar-nav ml-auto">
            <li className="nav-item">
              <Link to={"/login"} className="nav-link">
                Login
              </Link>
            </li>

            <li className="nav-item">
              <Link to={"/register"} className="nav-link">
                Sign Up
              </Link>
            </li>
          </div>
        )}
      </nav> */}





      {/* <Navbar style={{ marginBottom: 0, marginTop: 0, background: '#ffffff', borderRadius: 0 }} className="font-loader" sticky="top" collapseOnSelect expand="lg" bg="" variant="light">

        <Navbar.Brand href="#home">
          <img
            alt=""
            src="/logo-1-1.png"
            width="150"
            height="50"
            className="d-inline-block align-top"
          />{' '}
          React Bootstrap
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mr-auto">
            <Nav.Link exact activeClassName="active" href={"/"}>หน้าหลัก</Nav.Link>

            <Link exact activeClassName="active" to={"/MapLayer"} className="nav-link">
              แผนที่เสี่ยงภัยแล้ง
            </Link>

            <Link exact activeClassName="active" to={"/Stat"} className="nav-link">
              สรุปสถานการณ์ภัยแล้ง
            </Link>
            <Link to={"/login"} className="nav-link">
              วาดแปลงเกษตรกรรม
            </Link>
            <Link to={"/login"} className="nav-link">
              ดาวน์โหลดข้อมูล
            </Link>

            {showModeratorBoard && (
              <li className="nav-item">
                <Link to={"/mod"} className="nav-link">
                  Moderator Board
                </Link>
              </li>
            )}

            {showAdminBoard && (
              <li className="nav-item">
                <Link to={"/admin"} className="nav-link">
                  Admin Board
                </Link>
              </li>
            )}

            {currentUser ? (
              <li className="nav-item">
                <Link to={"/user"} className="nav-link">
                  ติดตามสถานการณ์ภัยแล้งรายแปลง
                </Link>
              </li>
            ) : (
              <li className="nav-item">
                <Link to={"/login"} className="nav-link">
                  ติดตามสถานการณ์ภัยแล้งรายแปลง
                </Link>
              </li>
            )}

            {currentUser && (
              <li className="nav-item">
                <Link to={"/user2"} className="nav-link">
                  ดาวน์โหลดข้อมูล
                </Link>
              </li>
            )}

            {currentUser ? (
              <li className="nav-item">
                <Link to={"/download"} className="nav-link">
                  ดาวน์โหลดข้อมูล
                </Link>
              </li>
            ) : (
              <li className="nav-item">
                <Link to={"/login"} className="nav-link">
                  ดาวน์โหลดข้อมูล
                </Link>
              </li>
            )}






            <Nav.Link href="#pricing">Pricing</Nav.Link>

            <NavDropdown title="Dropdown" id="collasible-nav-dropdown">
              <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>
            </NavDropdown>
          </Nav>

          <Nav>

            {currentUser ? (
              <div className="navbar-nav ml-auto">
                <li className="nav-item">
                  <Link to={"/profile"} className="">
                    {currentUser.username}
                  </Link>
                </li>
                <li className="nav-item">
                  <a href="/home" className="btn btn-outline-danger" onClick={logOut}>
                    ออกจากระบบ
                  </a>
                </li>
              </div>
            ) : (

              <div className="navbar-nav ml-auto">
                <li className="nav-item">
                  <Link to={"/login"} className="btn btn-outline-warning">
                    เข้าสู่ระบบ
                  </Link>
                </li>

                <li className="nav-item">
                  <Link to={"/register"} className="btn btn-outline-success">
                    ลงทะเบียน
                  </Link>
                </li>
              </div>


            )}


            <Nav.Link href="#deets">More deets</Nav.Link>
            <Nav.Link eventKey={2} href="#memes">
              Dank memes
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar> */}

      <Navbar style={{ background: 'rgb(255 255 255)' }} data-bs-theme="dark" collapseOnSelect expand="lg" sticky="top" className="font-loader">
        <Container fluid>
          <Navbar.Brand href="#home">
            <img
              alt=""
              src="/logo-1-1.png"
              width="150"
              height="50"
              className="d-inline-block align-top">
            </img>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
              <Nav activeKey={activeKey} className="me-auto">
                <Nav.Link href="/home">หน้าหลัก</Nav.Link>
                <Nav.Link href="/MapLayer">แผนที่เสี่ยงภัยแล้ง</Nav.Link>
                <Nav.Link href="/stat">สรุปสถานการณ์ภัยแล้ง</Nav.Link>
                {currentUser ? (
                  <Nav.Link activeKey={activeKey} href="/user">ติดตามสถานการณ์ภัยแล้งรายแปลง</Nav.Link>
                ) : (
                  <Nav.Link href="/login">ติดตามสถานการณ์ภัยแล้งรายแปลง</Nav.Link>
                )}
                {currentUser ? (
                  <Nav.Link href="/download">ดาวน์โหลดข้อมูล</Nav.Link>
                ) : (
                  <Nav.Link href="/login">ดาวน์โหลดข้อมูล</Nav.Link>
                )}
                &nbsp;
                {/* <NavDropdown title="Dropdown" id="collapsible-nav-dropdown">
                  <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.2">
                    Another action
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href="#action/3.4">
                    Separated link
                  </NavDropdown.Item>
                </NavDropdown> */}
              </Nav>
            </Nav>
            <Nav>
              {currentUser ? (
                <Nav.Link className="btn btn-outline-warning" activeKey={activeKey} href="/profile">{currentUser.username}</Nav.Link>
              ) :  (
                <Nav.Link className="btn btn-outline-warning" href="/login">เข้าสู่ระบบ</Nav.Link>
              )} &nbsp;&nbsp;&nbsp;
              {currentUser ? (
                <Nav.Link href="/home" className="btn btn-outline-danger" onClick={logOut} >ออกจากระบบ</Nav.Link>
              ) : (
                <Nav.Link className="btn btn-outline-success" href="/register">ลงทะเบียน</Nav.Link>
              )}

              {/* 
              {currentUser ? (

                <div className="navbar-nav ml-auto">
                  <Nav.Link href="/profile" className="btn btn-outline-warning">
                    {currentUser.username}
                  </Nav.Link>
                  {' '}
                  <Nav.Link href="/home" className="btn btn-outline-danger" onClick={logOut} >ออกจากระบบ</Nav.Link>



                </div>
              ) : (

                <div className="navbar-nav ml-auto">
                  <li className="nav-item">
                    <Link to={"/login"} className="btn btn-outline-warning">
                      เข้าสู่ระบบ
                    </Link>
                  </li>
                  {' '}
                  <li className="nav-item">
                    <Link to={"/register"} className="btn btn-outline-success">
                      ลงทะเบียน
                    </Link>
                  </li>
                </div>


              )} */}

              {/* <Nav.Link href="#deets">More deets</Nav.Link>
              <Nav.Link eventKey={2} href="#memes">
                Dank memes
              </Nav.Link> */}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>


      <div className="container-fluid mt-3">
        <Routes>
          <Route exact path={"/"} element={<Home />} />
          <Route exact path={"/home"} element={<Home />} />
          <Route exact path="/MapLayer" element={<MapLayer />} />
          <Route exact path="/MapLayer-dev" element={<MapLayer_dev />} />
          <Route exact path="/Stat" element={<Stat />} />
          <Route exact path="/download" element={<Download />} />
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/register" element={<Register />} />
          <Route exact path="/profile" element={<Profile />} />
          <Route path="/forgetPassword" element={<ForgetPassword />} />
          <Route path="/user" element={<BoardUser />} />
          <Route path="/user2" element={<BoardUser2 />} />
          <Route path="/mod" element={<BoardModerator />} />
          <Route path="/admin" element={<BoardAdmin />} />
          <Route path="/term-condition" element={<Termcondition />} />
          <Route path="/privacy-policy" element={<Privacypolicy />} />
          
        </Routes>
      </div>

      {/* <AuthVerify logOut={logOut}/> */}
    </div >

  );
};

export default App;
